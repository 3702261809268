body {
    margin: 0;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    padding: 0 30px;
}

.App {
    display: flex;
    flex-direction: column;
}

.loading {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    clear: both;
    margin: 20px auto;
    border: 4px #333 solid;
    border-top: 4px #666 solid;
    border-radius: 50%;
    -webkit-animation: loading 0.6s infinite linear;
    animation: loading 0.6s infinite linear;
}

@-webkit-keyframes loading {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}
@keyframes loading {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
